<template>
  <v-container id="data-tables" tag="section">
    <base-material-card color="indigo" icon="mdi-file-table-box-multiple-outline" inline class="px-5 py-3">
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Manage Groups
        </div>
      </template>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="groups"
        item-key="id"
        sort-by="id"
        :disable-pagination="true"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="6" md="2" class="text-sm-center">
              <v-dialog v-model="addGroupDialog" :retain-focus="false" max-width="800px" scrollable>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="mt-4 mr-0"
                  >
                    Create Group
                  </v-btn>
                </template>
                <v-card :loading="cardLoading">
                  <v-card-title>
                    <span class="headline">Create Group</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="newGroup.name" label="Enter a Group name"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea v-model="newGroup.description" label="Enter a Group description" counter max-length="300"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" v-on:click.stop="addGroupDialog = false">Nevermind</v-btn>
                    <v-btn :disabled="!newGroup.name" color="green darken-1" v-on:click.stop="createGroup()">Create</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-select
                :items="pageOptions"
                v-model="rowsPerPage"
                append-icon="mdi-chevron-down"
                class="ml-auto"
                label="Rows per Page"
                hide-details
                v-on:change="init()"
              >
              </v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-on:click.stop="editGroup(item)"
              v-bind="attrs"
              v-on="on"
              color="green darken-1"
            >
            mdi-pencil
            </v-icon>
            </template>
            <span>Edit Group</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click.stop="deleteConfirm(item)"
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete Group</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-on:click.stop="publishConfirm(item)"
              v-bind="attrs"
              v-on="on"
              color="green darken-1"
            >
            mdi-plus
            </v-icon>
            </template>
            <span>Publish Group</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <h2>Sorry, we couldn't find anything that matched that search.</h2>
        </template>
      </v-data-table>
      <v-dialog
        :retain-focus="false"
        v-model="deleteConfirmDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>
            This group will be deleted and removed from associated portals.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              v-on:click.stop="deleteConfirmDialog = false"
            >
              No, don't delete
            </v-btn>
            <v-btn
              color="red darken-1"
              v-on:click.stop="deleteGroup"
            >
            Yes, delete group
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :retain-focus="false"
        v-model="publishDialog"
        max-width="800"
      >
        <v-card>
          <v-card-title class="headline">Publish to Portal</v-card-title>
          <v-card-text>
            Select a portal to publish this group to.
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select v-model="selectedPortal" :items="portals" label="Select Portal"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              v-on:click.stop="publishDialog = false"
            >
              Nevermind
            </v-btn>
            <v-btn
              :disabled="!selectedPortal"
              color="green darken-1"
              v-on:click.stop="publishToPortal"
            >
            Yes, publish to portal
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="text-center pt-2">
        <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || isLoading === true" color="blue darken-1" v-on:click="previousPage()"><v-icon >mdi-chevron-left</v-icon></v-btn>
        <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || isLoading === true" color="blue darken-1" v-on:click="nextPage()"><v-icon >mdi-chevron-right</v-icon></v-btn>
      </div>
      <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>

<script>

import GetGroups from '@/Services/Groups/ListGroups'
import CreateGroup from '@/Services/Groups/CreateGroup'
import DeleteGroup from '@/Services/Groups/DeleteGroup'
import PublishGroup from '@/Services/Groups/PublishToPortal'
import GetPortals from '@/Services/Portals/GetPortals'

export default {
  name: 'GroupTable',
  data: () => ({
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeout: null,
    selectedPortal: null,
    selectedGroup: null,
    publishDialog: false,
    portals: [
      { value: null, text: 'Select a Portal' }
    ],
    groups: [],
    isLoading: false,
    cardLoading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    pageOptions: [5, 10, 20, 50, 100],
    rowsPerPage: 10,
    addGroupDialog: false,
    deleteConfirmDialog: false,
    newGroup: {
      name: '',
      description: ''
    },
    nextCursor: null,
    prevCursor: null,
    deleteGroupID: null
  }),
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.isLoading = true
      this.loadSnack()
      await GetGroups.getGroups(this.rowsPerPage)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'groups', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.isLoading = false
            this.finishSnack()
          } else {
            this.isLoading = false
            this.errorSnack(res.data.title)
          }
        })
    },
    async createGroup () {
      this.loadSnack()
      await CreateGroup.createGroup(this.newGroup)
        .then((res) => {
          if (res.status === 200) {
            this.addGroupDialog = false
            this.newGroup = {
              name: '',
              description: ''
            }
            this.init()
            this.finishSnack()
          } else {
            this.addGroupDialog = false
            this.errorSnack(res.data.title)
          }
        })
    },
    editGroup (item) {
      const url = '/manage/group/' + item.id
      this.$router.push(url)
    },
    deleteConfirm (item) {
      this.deleteGroupID = item.id
      this.deleteConfirmDialog = true
    },
    async deleteGroup () {
      this.loadSnack()
      await DeleteGroup.deleteGroup(this.deleteGroupID)
        .then((res) => {
          if (res.status === 200) {
            this.deleteConfirmDialog = false
            this.deleteGroupID = null
            this.init()
            this.finishSnack()
          } else {
            this.deleteGroupID = null
            this.deleteConfirmDialog = false
            this.errorSnack(res.data.title)
          }
        })
    },
    async publishConfirm (item) {
      this.selectedGroup = item.id
      await GetPortals.getPortals(20000)
        .then((res) => {
          if (res.status === 200) {
            res.data.results.forEach(element => {
              this.portals.push({ value: element.id, text: element.name })
            })
          }
        })
      this.publishDialog = true
    },
    async publishToPortal () {
      this.isLoading = true
      this.loadSnack()
      await PublishGroup.publishToPortal(this.selectedGroup, this.selectedPortal)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false
            this.selectedPortal = null
            this.selectedGroup = null
            this.publishDialog = false
            this.finishSnack()
          } else {
            this.isLoading = false
            this.errorSnack(res.data.title)
          }
        })
    },
    async previousPage () {
      this.isLoading = true
      await GetGroups.getPreviousPage(this.rowsPerPage, this.prevCursor)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'groups', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        })
    },
    async nextPage () {
      this.isLoading = true
      await GetGroups.getNextPage(this.rowsPerPage, this.nextCursor)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'groups', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        })
    },
    loadSnack () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Loading...'
      this.snackTimeout = -1
    },
    finishSnack () {
      this.snackColor = 'success'
      this.snackText = 'Done'
      this.snackTimeout = 3000
      this.chartLoaded = true
    },
    errorSnack (errorMessage) {
      this.snackColor = 'error'
      this.snackText = errorMessage
      this.snackTimeout = 5000
    }
  }
}
</script>

<script>
// import { api } from '@/http-common.js'
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async getGroups (rowsPerPage) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/groups?count=' + rowsPerPage
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getPreviousPage (rowsPerPage, prevCursor) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/groups?count=' + rowsPerPage + '&previous=' + prevCursor
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getNextPage (rowsPerPage, nextCursor) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/groups?count=' + rowsPerPage + '&next=' + nextCursor
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
